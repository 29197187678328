import { Injectable } from '@angular/core';
import { MenuItem } from 'src/app/data-types/menu.type';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor() { }


  menuItems: MenuItem[] = [
    { title: 'Users', link: '/company-users', icon: 'people-circle-outline' },
    { title: 'Clients', link: '/client-list', icon: 'people-circle-outline' },
    { title: 'Categories', link: '/categories', icon: 'list-circle-outline' },
    { title: 'Source', link: '/sources', icon: 'list-circle-outline' },
    { title: 'Enquiries', link: '/enquiries', icon: 'list-circle-outline' },
    { title: 'Reminders', link: '/reminders', icon: 'list-circle-outline' },
    { title: 'Appointment', link: '/appointment-list', icon: 'log-in-outline' },
    { title: 'Enquiries', link: '/assigned-enquiries', icon: 'log-in-outline' },
    // { title: 'Reminders', link: '', icon: 'log-in-outline' },
  ];

  getMenuItems(): MenuItem[] {
    return this.menuItems;
  }

}
