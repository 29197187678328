import { Injectable } from '@angular/core';
import * as Constants from '../../res/app.constants';
import { DibcHttpService } from '../dibcHttp/dibc-http.service';

@Injectable({
  providedIn: 'root'
})
export class EnquiryService {

  constructor(
    private dibcHttp: DibcHttpService
  ) { }
  async newEnquiry(basicformInput) {
    const url = Constants.ENQUIRY_PATH + 'new_enquiry';
    const apiResp = await this.dibcHttp.post(url, basicformInput);
    return apiResp;
  }
  async getEnquiries(filterdata) {
    const url = Constants.ENQUIRY_PATH + 'get_enquiries';
    const respData = await this.dibcHttp.post(url, filterdata, false);
    if (respData.enquiries) {
      return respData.enquiries;
    }
    return [];
  }
  async noOfPages() {
    const url = Constants.ENQUIRY_PATH + 'count_all_enquiry';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.noofpage) {
      return respData.noofpage;
    }
    return {};
  }
  async assignedEnquiries() {
    const url = Constants.ENQUIRY_PATH + 'assigned_enquiries';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.assignedenquiries) {
      return respData.assignedenquiries;
    }
    return [];
  }
  async getEnquiry(enqId) {
    const url = Constants.ENQUIRY_PATH + 'get_enquiry/' + enqId;
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.enquiry) {
      return respData.enquiry;
    }
    return {};
  }
  async removeEnquiry(enqid) {
    const url = Constants.ENQUIRY_PATH + 'enquiry_remove/' + enqid;
    const apiResp = await this.dibcHttp.post(url, {});
    return apiResp;
  }

  async last_thirty_days_enquiry() {
    const url = Constants.ENQUIRY_PATH + 'get_enquiry_last_thirty_days';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.enquiries) {
      return respData.enquiries;
    }
    return [];
  }
  async countEnquiry() {
    const url = Constants.ENQUIRY_PATH + 'count_enquiry';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.countenquiry) {
      return respData.countenquiry;
    }
    return {};
  }

  async unAssignedEnquiryCount() {
    const url = Constants.ENQUIRY_PATH + 'count_un_assigned_enquiry';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.unassignedencount) {
      return respData.unassignedencount;
    }
    return {};
  }
  async unAssignedEnquiries() {
    const url = Constants.ENQUIRY_PATH + 'un_assigned_enquiries';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.enquiries) {
      return respData.enquiries;
    }
    return [];
  }
}
