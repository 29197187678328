import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { User } from 'src/app/data-types/user.object';
import * as Constants from '../../res/app.constants';
import { AuthService } from '../auth/auth.service';
import { DibcHttpService } from '../dibcHttp/dibc-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userdata: any = '';
  mobile: any = '';
  public userObj = new User();
  public user: BehaviorSubject<User> = new BehaviorSubject<User>(this.userObj);
  private authkey = '';
  clients: any = [];
  executives: any = [];
  users: any = [];
  constructor(
    private authServ: AuthService,
    private dibcHttp: DibcHttpService
  ) {
    this.init();
  }

  async init() {
    // console.log('init user service');
    this.authkey = await this.getAuthKey();
    if (this.authkey) {
      this.getUserProfile();
      this.userObj.loggedIn = true;
      this.user.next(this.userObj);
    }
  }
  async getAuthKey() {
    if (!this.authkey) {
      this.authkey = await this.authServ.getAuthkey();
    }
    return this.authkey;
  }

  async registerUser(basicformInput) {
    const url = Constants.USER_API_PATH + '/' + 'registeruser';
    const apiResp = await this.dibcHttp.post(url, basicformInput);
    return apiResp;
  }

  async verifyOtp(basicformInput) {
    const url = Constants.USER_API_PATH + '/' + 'verify_otp';
    const apiResp = await this.dibcHttp.post(url, basicformInput);
    return apiResp;
  }

  async userLogin(basicformInput) {
    const url = Constants.USER_API_PATH + '/' + 'user_login';
    const apiResp = await this.dibcHttp.post(url, basicformInput);
    if (apiResp && apiResp.authkey) {
      this.authkey = apiResp.authkey;
      this.dibcHttp.authkey = this.authkey;
      this.authServ.setAuthkey(this.authkey);
      this.getUserProfileFromServer();
    }
    return apiResp;
  }



  async logout() {
    this.authServ.clear();
    this.userObj = new User();
    this.user.next(this.userObj);
  }
  async getUserProfile() {
    if (this.userObj.user_name.length === 0) {
      await this.getUserProfileFromServer();
    }
    return this.userObj;
  }
  async getUserProfileFromServer() {
    const url = Constants.USER_API_PATH + 'get_user_profile';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.status === 200) {
      this.userObj = respData.user;
      this.userObj.loggedIn = true;
      this.user.next(this.userObj);
    }
    return this.userObj;
  }
  async addprofiledata(userdata) {
    console.log(userdata);
    const apiResp = await this.dibcHttp.post('user/adduserdata', userdata);
    return apiResp;
  }

  async updateProfile(basicformInput) {
    const url = Constants.USER_API_PATH + 'update_profile';
    const apiResp = await this.dibcHttp.post(url, basicformInput);
    return apiResp;
  }

  async changePassword(password) {
    const url = Constants.USER_API_PATH + 'password_change';
    const apiResp = await this.dibcHttp.post(url, password);
    return apiResp;
  }

  async forgotpwdotfverify(basicformInput) {

    const url = Constants.USER_API_PATH + 'forgotpwdotfverify';
    const apiResp = await this.dibcHttp.post(url, basicformInput);
    return apiResp;
  }

  async resetPwd(basicformInput) {
    const url = Constants.USER_API_PATH + 'resetpassword';
    const apiResp = await this.dibcHttp.post(url, basicformInput);
    return apiResp;
  }
  /*Common function for data call and insert*/


  async getExecutives() {
    while (this.executives.wait) {
      await this.delay(1000);
    }
    let lastDateObj = new Date();

    lastDateObj.setHours(lastDateObj.getHours() - Constants.DATA_EXPIRY_HOURS);
    const lastTime = lastDateObj.getTime();
    if (!this.executives.time || this.executives.time < lastTime) {
      this.executives.wait = true;

      const url = Constants.PUB_API_PATH + 'get_executives';
      const respData = await this.dibcHttp.post(url, {}, false);
      if (respData.executives) {
        this.executives = respData.executives;
        this.executives.time = new Date().getTime();
        this.executives.wait = false;
      }
    }
    return this.executives;
  }

  async getUsers(filterdata) {
    const url = Constants.USER_API_PATH + 'get_users';
    const respData = await this.dibcHttp.post(url, filterdata, false);
    if (respData.users) {
      this.users = respData.users;
      return this.users;
    } else {
      return [];
    }
  }
  async getUser(userid) {
    const url = Constants.USER_API_PATH + 'get_user/' + userid;
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.user) {
      return respData.user;
    } else {
      return {};
    }
  }
  async removeUser(userid) {
    const url = Constants.USER_API_PATH + 'remove_user/' + userid;
    const apiResp = await this.dibcHttp.post(url, {});
    return apiResp;
  }
  async noOfPages() {
    const url = Constants.USER_API_PATH + 'count_all_user';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.noofpage) {
      return respData.noofpage;
    }
    return {};
  }
  /*Comman use function*/
  async delay(millis) {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => resolve(), millis)
    });
  }
}
