import { Component, Input, OnInit } from '@angular/core';
import { ClientService } from 'src/app/services/client/client.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit {
  @Input()
  clientId: number
  client: any = {};
  logdata: any = {};
  constructor(
    private clientServ: ClientService
  ) { }

  async ngOnInit() {
    console.log(this.clientId);
    this.client = await this.clientServ.getClient(this.clientId);
  }
  async addlogs(action) {
    this.logdata.clientid = this.clientId
    if (this.clientId) {
      this.logdata.logaction = action;
    }
    const resp = await this.clientServ.addLog(this.logdata);
    if (resp.status == '200') {
      console.log("page change")
    }
  }
}
