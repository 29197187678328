import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/list-page/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'new-category',
    loadChildren: () => import('./pages/form-page/new-category/new-category.module').then(m => m.NewCategoryPageModule)
  },
  {
    path: 'edit-category/:cateid',
    loadChildren: () => import('./pages/form-page/new-category/new-category.module').then(m => m.NewCategoryPageModule)
  },
  {
    path: 'new-client',
    loadChildren: () => import('./pages/form-page/new-client/new-client.module').then(m => m.NewClientPageModule)
  },
  {
    path: 'new-enquiry',
    loadChildren: () => import('./pages/form-page/new-enquiry/new-enquiry.module').then(m => m.NewEnquiryPageModule)
  },
  {
    path: 'edit-enquiry/:enqid',
    loadChildren: () => import('./pages/form-page/new-enquiry/new-enquiry.module').then(m => m.NewEnquiryPageModule)
  },
  {
    path: 'company-users',
    loadChildren: () => import('./pages/list-page/company-users/company-users.module').then(m => m.CompanyUsersPageModule)
  },
  {
    path: 'new-user',
    loadChildren: () => import('./pages/form-page/new-user/new-user.module').then(m => m.NewUserPageModule)
  },
  {
    path: 'edit-user/:userid',
    loadChildren: () => import('./pages/form-page/new-user/new-user.module').then(m => m.NewUserPageModule)
  },
  {
    path: 'enquiries',
    loadChildren: () => import('./pages/list-page/enquiries/enquiries.module').then(m => m.EnquiriesPageModule)
  },

  {
    path: 'client-list',
    loadChildren: () => import('./pages/list-page/client-list/client-list.module').then(m => m.ClientListPageModule)
  },
  {
    path: 'enq-updates/:enqid/:clientid',
    loadChildren: () => import('./pages/list-page/enq-updates/enq-updates.module').then(m => m.EnqUpdatesPageModule)
  },
  {
    path: 'new-enq-update/:enqid/:clientid',
    loadChildren: () => import('./pages/form-page/new-enq-update/new-enq-update.module').then(m => m.NewEnqUpdatePageModule)
  },
  {
    path: 'edit-enq-update/:enqid/:updateid',
    loadChildren: () => import('./pages/form-page/new-enq-update/new-enq-update.module').then(m => m.NewEnqUpdatePageModule)
  },
  {
    path: 'client-view/:clientid',
    loadChildren: () => import('./pages/view-page/client-view/client-view.module').then(m => m.ClientViewPageModule)
  },
  {
    path: 'user-view/:userid',
    loadChildren: () => import('./pages/view-page/user-view/user-view.module').then(m => m.UserViewPageModule)
  },
  {
    path: 'category-view/:cateid',
    loadChildren: () => import('./pages/view-page/category-view/category-view.module').then(m => m.CategoryViewPageModule)
  },
  {
    path: 'enquiry-view/:enqid/:clientid/:userid/:sourceid',
    loadChildren: () => import('./pages/view-page/enquiry-view/enquiry-view.module').then(m => m.EnquiryViewPageModule)
  },

  {
    path: 'assigned-enquiries',
    loadChildren: () => import('./pages/list-page/assigned-enquiries/assigned-enquiries.module').then(m => m.AssignedEnquiriesPageModule)
  },
  {
    path: 'reminders',
    loadChildren: () => import('./pages/list-page/reminders/reminders.module').then(m => m.RemindersPageModule)
  },
  {
    path: 'reminder-view/:enqid/:clientid/:userid/:reminderid',
    loadChildren: () => import('./pages/view-page/reminder-view/reminder-view.module').then(m => m.ReminderViewPageModule)
  },
  {
    path: 'sources',
    loadChildren: () => import('./pages/sources/sources.module').then(m => m.SourcesPageModule)
  },
  {
    path: 'new-source',
    loadChildren: () => import('./pages/new-source/new-source.module').then(m => m.NewSourcePageModule)
  },
  {
    path: 'edit-source/:sourceid',
    loadChildren: () => import('./pages/new-source/new-source.module').then(m => m.NewSourcePageModule)
  },
  {
    path: 'appointment-list',
    loadChildren: () => import('./pages/list-page/appointment-list/appointment-list.module').then(m => m.AppointmentListPageModule)
  },
  {
    path: 'residual-form',
    loadChildren: () => import('./pages/form-page/residual-form/residual-form.module').then(m => m.ResidualFormPageModule)
  },
  {
    path: 'consultation-form',
    loadChildren: () => import('./pages/form-page/consultation-form/consultation-form.module').then(m => m.ConsultationFormPageModule)
  },
  {
    path: 'consultation-list',
    loadChildren: () => import('./pages/list-page/consultation-list/consultation-list.module').then(m => m.ConsultationListPageModule)
  },
  {
    path: 'new-passowrd/:userid',
    loadChildren: () => import('./pages/form-page/new-passowrd/new-passowrd.module').then(m => m.NewPassowrdPageModule)
  },
  {
    path: 'new-appointment',
    loadChildren: () => import('./pages/form-page/new-appointment/new-appointment.module').then( m => m.NewAppointmentPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
