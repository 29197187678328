import { Component, Input, OnInit } from '@angular/core';
import { EnquiryService } from 'src/app/services/enquiry/enquiry.service';
import { EnqupdateService } from 'src/app/services/enqupdate/enqupdate.service';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.scss'],
})
export class EnquiryComponent implements OnInit {
  @Input()
  enqId: number;
  @Input()
  clientenq: string;
  enquiry: any = {};
  statuses: any = [];
  constructor(
    private enqServ: EnquiryService,
    private enqUpdateServ: EnqupdateService
  ) { }

  async ngOnInit() {
    this.statuses = await this.enqUpdateServ.getStatuses();
    if (this.enqId) {
      this.enquiry = await this.enqServ.getEnquiry(this.enqId);
    }
  }

}
