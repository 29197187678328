import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "./header/header.component";
import { MenuComponent } from "./menu/menu.component";
import { UserMenuComponent } from "./user-menu/user-menu.component";
import { EnquiryComponent } from "./enquiry/enquiry.component";
import { UserComponent } from "./user/user.component";
import { ClientComponent } from "./client/client.component";
import { ReminderComponent } from "./reminder/reminder.component";
import { EnqupdateComponent } from "./enqupdate/enqupdate.component";


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        RouterModule,
    ],
    declarations: [
        HeaderComponent,
        MenuComponent,
        UserMenuComponent,
        EnquiryComponent,
        UserComponent,
        ClientComponent,
        ReminderComponent,
        EnqupdateComponent

    ],
    exports: [
        HeaderComponent,
        MenuComponent,
        UserMenuComponent,
        EnquiryComponent,
        UserComponent,
        ClientComponent,
        ReminderComponent,
        EnqupdateComponent

    ]
})

export class ComponentModule { }