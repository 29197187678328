import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { User } from 'src/app/data-types/user.object';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user = new User();
  messages: any = [];
  msgCount = 0;

  constructor(
    private userServ: UserService,
    private modalCtrl: ModalController,
    public alertCtrl: AlertController
  ) { }

  async ngOnInit() {
    await this.userServ.user.subscribe(async u => {
      this.user = u;
    });
  }
  async openUserMenu() {

  }
  // async openLoginForm() {
  //   const modal = await this.modalCtrl.create({
  //     component: LoginComponent,
  //     cssClass: 'small'
  //   });
  //   modal.onDidDismiss().then(async (resp) => {
  //   })
  //   await modal.present();
  // }
}
