import { Injectable } from '@angular/core';
import * as Constants from '../../res/app.constants';
import { DibcHttpService } from '../dibcHttp/dibc-http.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  clients: any = [];
  constructor(
    private dibcHttp: DibcHttpService,
    private userServ: UserService
  ) { }

  async newClient(basicformInput) {
    const url = Constants.CLIENT_PATH + 'new_client';
    const apiResp = await this.dibcHttp.post(url, basicformInput);
    return apiResp;
  }
  async getClients(alldata = 'limited', filter = {}) {
    console.log(alldata);
    while (this.clients.wait) {
      await this.userServ.delay(500);
      console.log(this.clients.wait);
    }
    const lastDateObj = new Date();
    // lastDateObj.setHours(lastDateObj.getHours() - Constants.DATA_EXPIRY_HOURS);
    // const lastTime = lastDateObj.getTime();
    // if (!this.clients.time || this.clients.time < lastTime) {
    this.clients.wait = true;

    const url = Constants.CLIENT_PATH + 'get_clients/' + alldata;
    const respData = await this.dibcHttp.post(url, filter, false);
    if (respData.clients) {
      this.clients = respData.clients;
      this.clients.time = new Date().getTime();
      this.clients.wait = false;
    }
    return this.clients;
  }
  async getClient(clientId) {
    const url = Constants.CLIENT_PATH + 'get_client/' + clientId;
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.client) {
      return respData.client;
    } else {
      return {};
    }
  }
  async removeClient(clientid) {
    const url = Constants.CLIENT_PATH + 'remove_client/' + clientid;
    const apiResp = await this.dibcHttp.post(url, {});
    return apiResp;
  }
  async addLog(logdata) {
    const url = Constants.LOG_PATH + 'add_log';
    const apiResp = await this.dibcHttp.post(url, logdata);
    return apiResp;
  }
  async getClientEnquiryCount(clientId) {
    const url = Constants.CLIENT_PATH + 'count_enquiry_client_wise/' + clientId;
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.countenquiry) {
      return respData.countenquiry;
    } else {
      return {};
    }
  }
}
