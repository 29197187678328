import { Component, OnInit } from '@angular/core';
import { NavController, AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MenuItem } from 'src/app/data-types/menu.type';
import { User } from 'src/app/data-types/user.object';
import { MenuService } from 'src/app/services/menu/menu.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  menuItems: MenuItem[] = [];

  user = new User();
  subscription: Subscription;
  constructor(
    private menuService: MenuService,
    private userServ: UserService,
    private navCtrl: NavController,
    public alertCtrl: AlertController) { }

  async ngOnInit() {

    this.menuItems = this.menuService.getMenuItems()

    this.subscription = await this.userServ.user.subscribe(async u => {
      this.user = u;
    });


  }
  async logout() {
    await this.userServ.logout();
    document.getElementsByClassName('menu-content-open').item(0).classList.remove('menu-content-open');
    setTimeout(() => {
      this.navCtrl.navigateRoot('/');
    }, 1000);
  }
  async openInternalLink(link) {
    await document.getElementsByClassName('menu-content-open').item(0).classList.remove('menu-content-open');
    this.navCtrl.navigateRoot(link);
  }
  async openExternalLink(link) {
    // await Browser.open({ url: link });
  }
}
