import { Injectable } from '@angular/core';
import { Platform, LoadingController, AlertController, NavController } from '@ionic/angular';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import * as Constants from '../../res/app.constants'
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DibcHttpService {
  BASE_URL = '';
  BASE_API_URL = '';
  UPLOADS = '';
  authkey = '';
  i = 0;
  loadingElements = [];
  constructor(
    private plt: Platform,
    private authServ: AuthService,
    private http: HttpClient,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController,
    private router: Router
  ) {

    if (window.location.href.indexOf('localhost') < 0
      || this.plt.is('android') || this.plt.is('ios')

    ) {
      this.BASE_URL = Constants.EXT_URL;
    } else {
      this.BASE_URL = Constants.INT_URL;
    }
    this.BASE_API_URL = this.BASE_URL + Constants.API_PATH;
    //this.UPLOADS = this.BASE_API_URL + Constants.UPLOAD_PATH;

    this.init();
  }
  async init() {
    this.authkey = await this.authServ.getAuthkey();
  }

  async post(url, data, showLoading = true, datatype = "urlencoded") {
    if (!this.authkey) {
      await this.init();
    }
    this.i++;
    const li = this.i;
    if (showLoading) {
      await this.presentLoading(li);
    }
    url = this.BASE_API_URL + url;

    let contentType = "";
    let params: any;

    switch (datatype) {
      case "urlencoded":
        contentType = 'application/x-www-form-urlencoded';
        params = new HttpParams({
          fromObject: data,
        });
        break;

      case "multipart":
        contentType = '';
        const formData = new FormData();
        const keys = Object.keys(data);
        const values: any = Object.values(data);
        keys.forEach((k, i) => {
          formData.append(k, values[i]);
        });
        params = formData;
        break;

      case "json":
        contentType = "application/json";
        params = JSON.stringify(data);

        break;
    }

    let resp: any = {};

    const httpOptions = {
      headers: {
        DIBCAuthkey: this.authkey ? this.authkey : '',
        Source: 'app',
      },
      observe: 'response' as 'response', // get full resp. default is only body
      params: {} // url params
    };
    if (contentType) {
      httpOptions.headers['Content-Type'] = contentType;
    }
    resp = await this.http.post(url, params, httpOptions)
      .toPromise()
      .then(async httpResp => {
        switch (httpResp.status) {
          case 200:
            let respBody: any = httpResp.body;
            respBody.status = 200;
            if (showLoading && respBody.msg) {
              this.presentAlert('', respBody.msg, 'Success');
            }
            if (respBody.DIBCAuthkey) {
              this.authServ.setAuthkey(respBody.DIBCAuthkey);
              this.authkey = respBody.DIBCAuthkey;
            }
            return respBody;
            break;
          default:
            // always inform server about the error


            const httpErrResp: any = httpResp;
            const error = httpErrResp.error.error ? httpErrResp.error.err : 'Unidentified response, contact DIBC team.';
            this.presentAlert(
              'Code: ' + status,
              error

            );
            break;
        }
        resp = 1;
      }).catch(httpErrResp => {
        switch (httpErrResp.status) {
          case 500:
            this.presentAlert('Server Error');
            break;
          case 404:
            this.presentAlert('API Error');
            break;
          case 401:
            this.presentAlert('Authorization Error', 'Please logout & login again or contact system administrator');
            this.authServ.clear();
            this.navCtrl.navigateRoot('/');
            break;
          default:
            let error = 'Unidentified error, contact DIBC team..'
            try {
              error = httpErrResp.error.err ? httpErrResp.error.err : 'Unidentified error, contact DIBC team.';
            } catch (e) {

            }
            if (typeof error === 'object') {
              error = Object.values(error).join('\r\n');
              // error = '<ul><li>' + Object.values(error).join('</li><li>') + '</li></ul>';
            }
            this.presentAlert(
              error
            );
            break;
        }
        return false;
      });


    if (showLoading) {
      this.loadingElements[li].dismiss();
    }
    return resp;
  }

  async presentLoading(i) {
    this.loadingElements[i] = await this.loadingCtrl.create({
      message: 'Please wait...',
      spinner: 'crescent',
      // duration: 20000
    });
    return await this.loadingElements[i].present();
  }
  async presentAlert(status, msg = '', title = 'Error', btns: any = ['Ok']) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: status,
      message: msg,
      // buttons: btns/*Comment by Rajeev-and add timeout for alert message.*/
    });
    await alert.present();
    setTimeout(() => {
      alert.dismiss();
    }, 2000);
  }
}
