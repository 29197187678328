import { Component, Input, OnInit } from '@angular/core';
import { EnqupdateService } from 'src/app/services/enqupdate/enqupdate.service';

@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss'],
})
export class ReminderComponent implements OnInit {
  @Input()
  reminderId: number;
  @Input()
  reminderview: string;
  @Input()
  pending: string
  reminder: any = {};
  actions: any = [];
  constructor(
    private enqupdateServ: EnqupdateService
  ) { }

  async ngOnInit() {
    this.actions = await this.enqupdateServ.getActions();
    this.reminder = await this.enqupdateServ.getReminder(this.reminderId);

  }

}
