import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  @Input()
  userId: number
  user: any = {};
  constructor(
    private userServ: UserService
  ) { }

  async ngOnInit() {
    console.log(this.userId);
    this.user = await this.userServ.getUser(this.userId);

  }

}
