import { Injectable } from '@angular/core';
import * as Constants from '../../res/app.constants';
import { DibcHttpService } from '../dibcHttp/dibc-http.service';

@Injectable({
  providedIn: 'root'
})
export class EnqupdateService {
  constructor(
    private dibcHttp: DibcHttpService
  ) { }
  async getStatuses() {
    const url = Constants.PUB_API_PATH + 'get_statuses';
    const respData = await this.dibcHttp.post(url, {});
    if (respData.stauses) {
      return respData.stauses;
    } else {
      return [];
    }
  }
  async getActions() {
    const url = Constants.PUB_API_PATH + 'get_actions';
    const respData = await this.dibcHttp.post(url, {});
    if (respData.actions) {
      return respData.actions;
    } else {
      return [];
    }
  }
  async getEnqUpdates(enqid, clientId) {
    const url = Constants.ENQUPDATE_PATH + 'get_enquiry_updates/' + enqid + '/' + clientId;
    const respData = await this.dibcHttp.post(url, {});
    if (respData.enquiryupdates) {
      return respData.enquiryupdates;
    } else {
      return [];
    }
  }
  async newUpdate(basicformInput) {
    const url = Constants.ENQUPDATE_PATH + 'enquiry_update';
    const apiResp = await this.dibcHttp.post(url, basicformInput);
    return apiResp;
  }
  async getEnqUpdate(updateId) {
    const url = Constants.ENQUPDATE_PATH + 'get_enquiry_update';
    const respData = await this.dibcHttp.post(url, { updateid: updateId });
    if (respData.enquiryupdate) {
      return respData.enquiryupdate;
    } else {
      return {};
    }
  }
  async getEnqReminders(filterdata) {
    const url = Constants.ENQUPDATE_PATH + 'get_reminders';
    const respData = await this.dibcHttp.post(url, filterdata);
    if (respData.reminders) {
      return respData.reminders;
    } else {
      return [];
    }
  }
  async getReminder(reminderId) {
    const url = Constants.ENQUPDATE_PATH + 'get_reminder/' + reminderId;
    const respData = await this.dibcHttp.post(url, {});
    if (respData.reminder) {
      return respData.reminder;
    } else {
      return [];
    }
  }
  async reminderCount() {
    const url = Constants.ENQUPDATE_PATH + 'count_reminder';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.remindercount) {
      return respData.remindercount;
    }
    return {};
  }
  async pendingReminderCount() {
    const url = Constants.ENQUPDATE_PATH + 'count_pending_reminder';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.pendingreminder) {
      return respData.pendingreminder;
    }
    return {};
  }
  async noOfPages() {
    const url = Constants.ENQUPDATE_PATH + 'count_all_reminder';
    const respData = await this.dibcHttp.post(url, {}, false);
    if (respData.noofpage) {
      return respData.noofpage;
    }
    return {};
  }
}
