import { Component, Input, OnInit } from '@angular/core';
import { EnqupdateService } from 'src/app/services/enqupdate/enqupdate.service';

@Component({
  selector: 'app-enqupdate',
  templateUrl: './enqupdate.component.html',
  styleUrls: ['./enqupdate.component.scss'],
})
export class EnqupdateComponent implements OnInit {
  @Input()
  updateId: any
  update: any = {};
  statuses: any = [];
  actions: any = [];
  constructor(
    private enqUpdate: EnqupdateService
  ) { }

  async ngOnInit() {

    this.update = await this.enqUpdate.getEnqUpdate(this.updateId);
    console.log(this.update);
    this.statuses = await this.enqUpdate.getStatuses();
    this.actions = await this.enqUpdate.getActions();
  }

}
